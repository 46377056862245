import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Privacy = () => (
    <Layout page="Privacy Policy">
        <Seo title="Privacy Policy" 
             description="monoteiではウェブ制作を行っています。monoteinはハイパフォーマンスなウェブサイトの制作を専門にしています。SSG、Jamstack、ヘッドレスCMSといった最新テクノロジーでウェブサイトのスピードを高速化し、ストレスフリーなウェブ体験を提供することで顧客満足度、サイト回遊率、アイテム購入率を高めてホームページを成功に導きます。"
        />
        <div className="container privacy">
            <div className="content">
                <div className="row">
                    <div className="col-12">
                        <div className="title">
                            <h2>プライバシーポリシー</h2>
                        </div>
                        <div className="main-text">
                            <p>当サイト上で提供するサービス（以下,「本サービス」といいます。）における、ユーザーの個人情報の取扱いについて、以下のとおりプライバシーポリシー（以下、「本ポリシー」といいます。）を定めます。</p>
                            
                            <h3>第1条（個人情報）</h3>
                            <p>「個人情報」とは、個人情報保護法にいう「個人情報」を指すものとし、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日、住所、電話番号、連絡先その他の記述等により特定の個人を識別できる情報及び容貌、指紋、声紋にかかるデータ、及び健康保険証の保険者番号などの当該情報単体から特定の個人を識別できる情報（個人識別情報）を指します。</p>
                            <h3>第2条（個人情報の収集方法）</h3>
                            <p>当サイトを通じてご連絡された場合、氏名、住所、電話番号、メールアドレス、銀行口座番号、運転免許証番号などの個人情報をお尋ねすることがあります。</p>
                            <h3>第3条（個人情報を収集・利用する目的）</h3>
                            <p>当サイトが個人情報を収集・利用する目的は、以下のとおりです。
                                <br/>• サービスの提供・運営のため
                                <br/>• ユーザーからのお問い合わせに回答するため（本人確認を行うことを含む）
                                <br/>• メンテナンス、重要なお知らせなど必要に応じたご連絡のため
                                <br/>• 利用規約に違反したユーザーや、不正・不当な目的でサービスを利用しようとするユーザーの特定をし、ご利用をお断りするため
                                <br/>• 上記の利用目的に付随する目的
                            </p>
                            <h3>第4条（利用目的の変更）</h3>
                            <p>利用目的が変更前と関連性を有すると合理的に認められる場合に限り、個人情報の利用目的を変更するものとします。利用目的の変更を行った場合には、変更後の目的について、当サイト上で公表するものとします。</p>
                            <h3>第5条（個人情報の第三者提供）</h3>
                            <p>次に掲げる場合を除いて、あらかじめユーザーの同意を得ることなく、第三者に個人情報を提供することはありません。ただし、個人情報保護法その他の法令で認められる場合を除きます。
                                <br/>• 人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき
                                <br/>• 公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき
                                <br/>• 国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるときl
                            </p>
                            <h3>第6条（個人情報の削除、利用停止）</h3>
                            <p>当サイトを通じて本人から個人情報の削除、利用停止を希望する場合は、本人であることを確認の上、速やかに対応いたします。</p>
                            <h3>第7条（プライバシーポリシーの変更）</h3>
                            <p>本ポリシーの内容は、法令その他本ポリシーに別段の定めのある事項を除いて、ユーザーに通知することなく、変更することができるものとします。変更後のプライバシーポリシーは、本ウェブサイトに掲載したときから効力を生じるものとします。</p>
                            <h3>第8条（お問い合わせ窓口）</h3>
                            <p>本ポリシーに関するお問い合わせは、下記メールアドレスまでお願いいたします。
                                <br/>contact@monotein.com 
                            </p>     
                        </div>           
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)

export default Privacy
